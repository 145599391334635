import { SummaryLinesProps } from "./SummaryLines"

export const AlternativeSummaryLines = ({ styles, translations, cart, showFreightPopup }: SummaryLinesProps) => {
    return <>
        <h2 className={styles.summaryTitle}>{translations["checkout.yourOrder"]}</h2>
        <div className={styles.totalContent}>
            <div>
                <p>
                    {translations["checkout.subtotal"]}
                    <span>{cart.summary.listPricesTotal}</span>
                </p>
            </div>
            {cart.summary.depositItemsCount > 0 && <div>
                <p>
                    {translations["checkout.depositTotal"]}
                    <span>+{cart.summary.depositPricesTotal}</span>
                </p>
            </div>}
            <div>
                <p>
                    {translations["checkout.yourDiscount"]}
                    <span>-{cart.summary.discountTotal}</span>
                </p>
            </div>
            <div>
                <p className={styles.summaryBold}>
                    {translations["checkout.totalWithoutVatAndShipping"]}
                    <span>{cart.summary.subTotal}</span>
                </p>
            </div>
            <div>
                <p>
                    {translations["checkout.vat"]}
                    <span>{cart.summary.taxTotal}</span>
                </p>
            </div>
            {!!cart.summary.remanTaxTotal &&
                <div>
                    <p>
                        {translations["checkout.atstBeterg"]}
                        <span>{cart.summary.remanTaxTotal}</span>
                    </p>
                </div>
            }
            <div>
                <p>
                    {translations["checkout.totalWithoutShipping"]}
                    <span>{cart.summary.total}</span>
                </p>
            </div>
            {cart.deliveryMethods.find(d => d.isSelected)?.methodType !== 1 &&
                <div>
                    <p>
                        <span className={styles.freightCost} onClick={showFreightPopup}>
                            {translations["checkout.shipping"]}
                        </span>
                    </p>
                </div>
            }
            <div className={styles.endingLine}></div>
        </div>
    </>
}