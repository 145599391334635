import cn from "classnames";
import * as styles from "../Item.module.scss";
import { MouseEvent } from "react";
import { popup, ButtonResult, ButtonType, PopupSize, QuantitySelector, Design, Mode } from "ui";
import { Inventories } from "ui/src/components/Inventories/Inventories";
import { LineItem } from "ui/src/types";

type ItemProps = {
    part: LineItem;
    onChange: (code: string, quantity: number) => void;
    onRemove: (code: string, callback: () => void) => void;
};
const translations = window.app.preloadState.translation;
const isLoggedIn = (window as any).app.preloadState.isLoggedIn;
const itemsWithoutPriceAllowedInCart = (window as any).app.preloadState.itemsWithoutPriceAllowedInCart;

export const Item = ({ part, onChange, onRemove }: ItemProps) => {
    const minimumQuantity = part.inventory?.minimumQuantity ?? 0;
    const maximumQuantity = part.inventory?.maximumQuantity ?? 0;
    const quantityStep = part.inventory?.quantityStep ?? 0;
    const isAvailableInWarehouse = part.inventory?.warehouse?.isAvailable ?? false;
    const isAvailableInBranch = part.inventory?.branch?.hasEnoughStock ?? false;

    const onRemoveClickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onRemove(part.code, () => { });
    };

    const onRemoveHandler = (callback: () => void) => {
        onRemove(part.code, callback);
    }

    const onChangeHandler = (quantity: number) => {
        onChange(part.code, quantity);
    }

    const showInventoryPopup = (ev: React.MouseEvent) => {
        ev.preventDefault();
        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.group}>
                <a className={styles.group} href={part.url}>
                    <img className="objectFitContain" src={(part.imageUrl ?? "")+"?width=80"} alt={part.displayName} width="80" />
                    <div className={styles.infoBlock}>
                        <p>
                            <span className={styles.title}>{part.displayName}</span>
                            {part.isUsedItem && <span className={styles.usedLabel}>{part.usedLabel}</span>}
                        </p>
                        <p className={cn(styles.articleNumber, "firstLetterCapital")}>
                            {part.isUsedItem && part.sparePartItemNumber}
                            {part.code && part.isUsedItem && <span className={styles.codeSeparator}>|</span>}
                            {part.code && part.code}
                        </p>
                        {part.labels?.length>0 &&
                            <p className={cn(styles.labels)}>
                                {part.labels.map(label =>
                                    <span key={label.text} className={styles.label}>{label.text}</span>
                                )}
                            </p>   
                        }
                        <p className={styles.additionalInfo}>
                            <span className={"firstLetterCapital"}>{translations["checkout.quantityFrp"]}: {part.inventory?.packageQuantity ?? 0}</span>
                            {!!part.unitId && <span className={"firstLetterCapital"}>{translations["checkout.unit"]}: {part.unitId}</span>}
                            {part.isUsedItem && <span className={styles.qualityClass}>{part.qualityClass}</span>}
                            {!part.isUsedItem && <span className="d-contents d-lg-none">
                                {(part.unitPriceAmount > 0 || !itemsWithoutPriceAllowedInCart) ? <>
                                    <span
                                        className={cn("truckIcon", {
                                            [styles.redTruck]: !isAvailableInWarehouse,
                                            [styles.truck]: isAvailableInWarehouse,
                                        })}
                                    >
                                        {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: part.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                                    </span>
                                    {part.inventory?.branch?.branchName ?
                                        <span
                                            onClick={showInventoryPopup}
                                            className={cn({
                                                [styles.check]: isAvailableInBranch,
                                                [styles.close]: !isAvailableInBranch,
                                            })}
                                        >
                                            {part.inventory?.branch?.branchName}
                                        </span>
                                        :
                                        <span
                                            onClick={showInventoryPopup}
                                        >
                                            {translations["inventories.selectBranch"]}
                                        </span>
                                    }
                                </> : <span className={cn("truckIcon", styles.truck)}>
                                    <span className={"d-inline-block firstLetterCapital"}>{translations["common.deliveryTimeToBeChecked"]}</span>
                                </span>}
                            </span>}
                        </p>
                    </div>
                </a>
                <div className={styles.md}>
                    <a href="#" onClick={onRemoveClickHandler} className={styles.remove}></a>
                </div>
            </div>
            <div className={styles.inventories}>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.stockStatus"]}:</div>
                <div className={cn(styles.md, styles.inventoriesLabels)}>
                    {(part.unitPriceAmount > 0 || !itemsWithoutPriceAllowedInCart) ? <>
                    <span
                        className={cn("firstLetterCapital truckIcon", {
                            [styles.redTruck]: !isAvailableInWarehouse,
                            [styles.truck]: isAvailableInWarehouse
                        })}
                    >
                        {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: part.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : translations["inventoryPopup.notInStock"]}
                    </span>
                    <span
                        onClick={showInventoryPopup}
                        className={cn( {
                            [styles.check]: isAvailableInBranch,
                            [styles.close]: !isAvailableInBranch,
                        })}
                    >
                        {part.inventory?.branch?.branchName}
                    </span>
                    </> : <span className={cn("truckIcon", styles.truck)}>
                            <span className={"d-inline-block firstLetterCapital"}>{translations["common.deliveryTimeToBeChecked"]}</span>
                        </span>}
            </div>
            </div>
            <div className={styles.prices}>
                {part.unitPriceAmount > 0 && <>
                    {part.hasDiscount && (
                        <div className="d-md-none">
                            <p className={styles.discountedPrice}>{part.unitDiscountedPrice}</p>
                        </div>
                    )}
                    <div>
                        <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.price"]}:</div>
                        <p
                            className={cn(styles.originalPrice, {
                                [styles.strike]: part.hasDiscount,
                            }, "d-md-none")}
                        >
                            {part.unitBasePrice}
                        </p>
                        <p
                            className={cn(styles.sum, "d-none d-md-block")}
                        >
                            {part.unitDiscountedPrice ?? part.unitBasePrice}
                        </p>
                    </div>
                    {part.hasDiscount && (
                        <div>
                            <div className="firstLetterCapital d-inline-block">{translations["checkout.discount"]}:</div>
                            <span>
                                <span
                                    className={cn(styles.strike, styles.originalPrice, "m-right--x3 d-none d-md-inline")}
                                >
                                    {part.unitBasePrice}
                                </span>
                                <span className={cn(styles.discount, "d-inline-block")}>{part.discountPercentageDisplay}</span>
                            </span>
                        
                        </div>
                    )}
                </>}
            </div>
            <div className={styles.quantity}>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.quantity"]}:</div>
                {part.isUsedItem
                    ? <div className={styles.usedItemQuantity}>1</div>
                    : <QuantitySelector
                        forceMinValue={true}
                        mode={Mode.Buy}
                        requestQuotationByEmail=""
                        contactSupportLink=""
                        step={quantityStep}
                        min={minimumQuantity}
                        max={maximumQuantity}
                        initialValue={part.quantity}
                        onRemove={onRemoveHandler}
                        onChange={onChangeHandler}
                        design={Design.Normal} />
                }
            </div>
            <div className={styles.sumWrapper}>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.total"]}:</div>
                <p className={styles.sum}>{part.unitPriceAmount > 0 && part.totalPrice}</p>
                <a href="#" onClick={onRemoveClickHandler} className={cn(styles.nmd, styles.remove)}>
                    {translations["checkout.removeItem"]}
                </a>
            </div>
        </div>
    );
};
