import * as styles from "./order-class-options.module.scss";
import { useEffect, useMemo, useState } from "react";
import { InputText } from "ui";
import { OrderClass, SelectedVehicle } from "ui/src/types";
import cn from "classnames";

type IOrderClassOptions = {
    orderClasses: OrderClass[];
    selectedMachineModel: string | null;
    selectedSerialNumber: string | null;
    serialNumberRegex: string;
    onOrderClassChange: (orderClass: string) => void;
    machineSuggestions: SelectedVehicle[];
};

export const OrderClassOptions = ({ orderClasses, selectedMachineModel, selectedSerialNumber, serialNumberRegex, onOrderClassChange, machineSuggestions }: IOrderClassOptions) => {
    const machines = useMemo(() => machineSuggestions.map(machine => ({
        ...machine,
        searchTerm: `${machine.salesModel ?? ""} (${machine.serialNumber ?? ""})`.toLocaleUpperCase()
    })), [machineSuggestions]);

    const translations = window.app.preloadState.translation;
    const [orderClass, setOrderClass] = useState(orderClasses.find(o => o.isSelected));
    const [machineModel, setMachineModel] = useState(selectedMachineModel ?? "");
    const [serialNumber, setSerialNumber] = useState(selectedSerialNumber ?? "");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [suggestedMachineModels, setSuggestedMachineModels] = useState(machines);

    const serialNumberRegExp = new RegExp(serialNumberRegex);
    const [serialNumberValidation, setSerialNumberValidation] = useState<string>();
    const updateSerialNumberValidation = (serialNumber: string) => {

        if (!orderClass?.machineInfoRequired) {
            setSerialNumberValidation(serialNumberRegExp.test(serialNumber) || !serialNumber ? undefined : translations["checkout.serialNumberIsInvalid"]);
        } else {
            setSerialNumberValidation(!!serialNumber
                ? (serialNumberRegExp.test(serialNumber) ? undefined : translations["checkout.serialNumberIsInvalid"])
                : translations["checkout.serialNumberIsRequired"]);
        }
    }

    const [machineModelValidation, setMachineModelValidation] = useState<string>();
    const updateMachineModelValidation = (machineModel: string) => {
        if (!orderClass?.machineInfoRequired) {
            setMachineModelValidation(undefined);
        } else {
            setMachineModelValidation(!!machineModel ? undefined : translations["checkout.machineModelIsRequired"]);
        }
    }

    const onOption = async (value: string) => {
        if (value == "-1") {
            onOrderClassChange(value);
            return;
        }
        const body = { orderClass: value }
        const cartUpdateResponse = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(body),
            method: "POST"
        });
        if (!cartUpdateResponse.ok) {
            console.error(cartUpdateResponse.text());
        }

        const userUpdateResponse = await fetch(`/api/user/selected-order-class`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(value),
            method: "PUT"
        });
        if (!userUpdateResponse.ok) {
            console.error(userUpdateResponse.text());
        }

        onOrderClassChange(value);
    };

    const onMachineInfoChange = async () => {
        const body = {
            machineInfo: {
                machineModel: machineModel,
                serialNumber: serialNumber
            }
        }
        const cartUpdateResponse = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(body),
            method: "POST"
        });
        if (!cartUpdateResponse.ok) {
            console.error(cartUpdateResponse.text());
        }

        onOrderClassChange(orderClass?.value ?? "-1");
    };

    const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);

    const selectMachineSuggestion = (machineModel: string, serialNumber: string) => {
        setMachineModel(machineModel);
        setSerialNumber(serialNumber);
        closeDropdown();
    }

    useEffect(() => {
        updateMachineModelValidation(machineModel);
        onMachineInfoChange();
    }, [machineModel]);

    useEffect(() => {
        updateSerialNumberValidation(serialNumber);
        onMachineInfoChange();
    }, [serialNumber]);

    useEffect(() => {
        updateMachineModelValidation(machineModel);
        updateSerialNumberValidation(serialNumber);
    }, [orderClass]);

    const filterSuggestions = (machineModel: string) => {
        setSuggestedMachineModels(machines.filter(machine => machine.searchTerm.includes(machineModel.toLocaleUpperCase())));
    }

    const closeDropdown = () => {
        setSuggestedMachineModels(machines);
        setIsDropdownOpen(false);
    }

    return (
        <>
            <h3 className={styles.title}>{translations["checkout.orderClass"]}</h3>
            <div className={styles.orderClassDescription}>
                {orderClass?.description}
            </div>
            <div className={styles.dropdownWrapper}>
                <select required className={styles.orderClassOptions} value={orderClass?.value} onChange={ev => {
                    setOrderClass(orderClasses.find(o => o.value === ev.target.value));
                    onOption(ev.target.value);
                    setIsFirstTimeRender(false);
                }}>
                    <option value="-1">{translations["checkout.selectOrderClassOption"]}</option>
                    {orderClasses.map(orderClass => <option value={orderClass.value} key={orderClass.value}>
                        {orderClass.displayName}
                    </option>)}
                </select>
                {(orderClass?.value == null && !isFirstTimeRender) && <span className="form-error">{translations["checkout.orderClassRequired"]}</span>}
            </div>
            <div className={styles.twoColumns}>
                <div tabIndex={-1} className={cn(styles.machineInfo, styles.machineSalesModelInputContainer)}>
                    <InputText
                        name="machineModel"
                        label={translations["checkout.machineModel"]}
                        value={machineModel}
                        onChange={ev => { filterSuggestions(ev.target.value); setMachineModel(ev.target.value) }}
                        onKeyDown={e => setIsDropdownOpen(true)}
                        error={machineModelValidation}
                        autocomplete="off"
                    />
                    {suggestedMachineModels?.length > 0 && isDropdownOpen && <div className={styles.orderClassMachineSuggestionsContainer}>
                        {suggestedMachineModels.map(machine => (
                            <span key={machine.serialNumber} onClick={() => selectMachineSuggestion(machine.salesModel!, machine.serialNumber ?? "")} className={styles.suggestedMachineRecord}>
                                {machine.salesModel} ({machine.serialNumber})
                            </span>
                        ))}
                    </div>}
                </div>
                <div className={styles.machineInfo}>
                    <InputText
                        name="serialNumber"
                        label={translations["checkout.serialNumber"]}
                        value={serialNumber}
                        onChange={ev => setSerialNumber(ev.target.value)}
                        error={serialNumberValidation}
                        autocomplete="off"
                    />
                </div>
            </div>
        </>
    );
};